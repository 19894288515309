import React, { useContext } from "react";

import StoreContext from "../context/StoreContext";
import LineItem from "../components/lineItem";

const Cart = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  const handleCheckout = () => {
    window.open(checkout.webUrl);
  };

  const lineItems = checkout.lineItems.map((item) => (
    <LineItem key={item.id.toString()} item={item} />
  ));

  return (
    <div className="flex-1 flex flex-col">
      {lineItems.length ? (
        <>
          <div className="flex-1 px-12 ">{lineItems}</div>
          <button
            className="button "
            onClick={handleCheckout}
            disabled={checkout.lineItems.length === 0}
          >
            Checkout (£{checkout.totalPrice})
          </button>
        </>
      ) : (
        <p className="px-12">Nothing to see here.</p>
      )}
    </div>
  );
};

export default Cart;
