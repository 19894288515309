/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import { Link } from "gatsby";

import StoreContext from "../context/StoreContext";
import { AiOutlineMinusCircle } from "react-icons/ai";

const LineItem = (props) => {
  const { item } = props;
  const {
    removeLineItem,
    store: { client, checkout },
  } = useContext(StoreContext);

  const variantImage = item.variant.image ? (
    <img
      src={item.variant.image.src}
      alt={`${item.title} product shot`}
      height="60px"
    />
  ) : null;

  const selectedOptions = item.variant.selectedOptions
    ? item.variant.selectedOptions.map(
        (option) => `${option.name}: ${option.value} `
      )
    : null;

  const handleRemove = () => {
    removeLineItem(client, checkout.id, item.id);
  };

  console.log(item);
  return (
    <div className="flex items-center">
      <Link
        to={`/product/${item.variant.product.handle}/`}
        className="w-24 mr-2"
      >
        {variantImage}
      </Link>
      <div className="p-2">
        <p>
          <strong>{item.title}</strong>
        </p>
        <p>
          {item.variant.title === !"Default Title" ? item.variant.title : ""}
        </p>
        <p>{selectedOptions}</p>
      </div>
      <div className="p-2">{item.quantity}</div>
      <button className="text-xl px-2" onClick={handleRemove}>
        <AiOutlineMinusCircle />
      </button>
    </div>
  );
};

export default LineItem;
