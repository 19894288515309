import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Header from "./header";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { SiGooglemaps } from "react-icons/si";
import ContextProvider from "../provider/ContextProvider";
import Ticker from "react-ticker";
import showdown from "showdown";

const converter = new showdown.Converter();
import ThemeContext from "../context/ThemeContext";

function Layout({ children, fixedHeader, title, parent }) {
  const date = new Date();
  const [visible, setVisible] = useState(false);
  const { markdownRemark } = useStaticQuery(graphql`
    query MyQuery {
      markdownRemark(fields: { slug: { eq: "/" } }) {
        frontmatter {
          ticker
        }
      }
    }
  `);

  const words = markdownRemark.frontmatter.ticker.split("\n");
  const [inHover, setHover] = useState(false);

  return (
    <ContextProvider>
      <ThemeContext.Provider value={{ visible, setVisible }}>
        <div className="font-sans text-xl font-light text-black">
          {markdownRemark.frontmatter.ticker && (
            <div
              className="bg-gray-900 text-white text-sm py-2 ticker"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <Ticker move={!inHover}>
                {({ index }) => {
                  console.log(index);
                  return (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: converter.makeHtml(words[index % words.length]),
                      }}
                    />
                  );
                }}
              </Ticker>
            </div>
          )}
          <Header fixedHeader={fixedHeader} />
          {title && (
            <div className="text-base font-semibold tracking-wider text-white uppercase bg-primary">
              <div className="container">
                <div className="py-4">
                  <ul className="flex list-none">
                    <li className="opacity-50">
                      <a className="px-2" href="/">
                        Home
                      </a>
                      /
                    </li>
                    {parent && (
                      <li className="opacity-50">
                        <Link className="px-2" to={parent.node.fields.slug}>
                          {parent.node.frontmatter.title}
                        </Link>
                        /
                      </li>
                    )}
                    <li>
                      <span className="px-2">{title}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
          <main>{children}</main>
          <footer className="py-12 text-base bg-gray-300">
            <div className="container">
              <div className="md:flex px-4 justify-between">
                <div className="mb-4">
                  <h4 className="mb-2">Get in touch</h4>
                  <p className="mb-4">
                    Balado Airfield
                    <br />
                    Kinross
                    <br />
                    KY13 0RF
                    <br />
                    Scotland
                  </p>
                  <p>
                    <a
                      href="https://www.google.com/maps/dir/50.8350662,-0.1950339/balado+airfield/"
                      className="flex items-center text-primary"
                    >
                      <SiGooglemaps />{" "}
                      <span className="px-2">Find us on GoogleMaps »</span>
                    </a>
                  </p>
                </div>
                <nav className="mb-4 ">
                  <a
                    href="https://pkleader.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/assets/pkleader.png" alt="PL leaders" />
                  </a>
                </nav>
                <nav className="text-sm md:text-right">
                  <h4 className="mb-2">Follow us</h4>
                  <ul className="flex md:justify-end mb-4 -mx-4 text-2xl">
                    <li>
                      <a
                        href="https://www.facebook.com/baladoairfield"
                        className="inline-block px-4 "
                      >
                        <FaFacebook />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://instagram.com/balado_airfield"
                        className="inline-block px-4"
                      >
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                  <p>
                    <a href="/contact-us">Contact us</a>
                  </p>
                  <p>&copy; {date.getFullYear()} Balado Airfield Ltd. </p>
                </nav>
              </div>
            </div>
          </footer>
        </div>
      </ThemeContext.Provider>
    </ContextProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  fixedHeader: PropTypes.bool,
  title: PropTypes.string,
  parent: PropTypes.object,
};

Layout.defaultProps = {
  fixedHeader: false,
};

export default Layout;
