/* eslint-disable react/prop-types */
import { Link } from "gatsby";
import React, { useState, useContext, useEffect } from "react";
import StoreContext from "../context/StoreContext";
import reduce from "lodash/reduce";
import Cart from "../components/cart";
import { AiOutlineCloseCircle, AiOutlineShoppingCart } from "react-icons/ai";
import { BsCursorFill } from "react-icons/bs";
import ThemeContext from "../context/ThemeContext";
import axios from "axios";
import { throttleAdapterEnhancer } from "axios-extensions";
import { FaFacebook, FaInstagram } from "react-icons/fa";

const useQuantity = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext);
  const items = checkout ? checkout.lineItems : [];
  const total = reduce(items, (acc, item) => acc + item.quantity, 0);
  return [total !== 0, total];
};

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const [hasItems, quantity] = useQuantity();
  const [weather, setWeather] = useState(
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("weather"))
      : null
  );
  const { visible, setVisible } = useContext(ThemeContext);
  const http = axios.create({
    params: { APPID: "b62a1250112ba45a7f27a9bf497c609f", q: "Balado,Scotland" },
    headers: { "Cache-Control": "no-cache" },
    adapter: throttleAdapterEnhancer(axios.defaults.adapter, {
      threshold: 60 * 60 * 1000,
    }),
  });
  useEffect(() => {
    async function fetchData() {
      let weather = localStorage.getItem("weather");
      if (weather) {
        setWeather(JSON.parse(weather));
      } else {
        const result = await http.get(
          "https://cors.bridged.cc/https://api.openweathermap.org/data/2.5/weather"
        );
        localStorage.setItem("weather", JSON.stringify(result.data));

        setWeather(result.data);
      }
    }

    if (!weather && typeof window !== "undefined") {
      fetchData();
    }
  }, []);

  return (
    <>
      <div
        className={`${
          visible ? `  ` : `offscreen`
        }  shadow-lg cart fixed h-full w-full z-30 right-0 max-w-lg duration-300 transition flex`}
      >
        <div className="flex flex-col flex-1 bg-white">
          <h2
            onClick={() => setVisible(false)}
            className="px-12 py-8 mb-8 text-2xl font-semibold cursor-pointer"
          >
            Your Basket &raquo;
          </h2>
          <Cart />
        </div>
      </div>
      <header className="top-0 z-20 w-full bg-primary bg-gradient">
        <div className="container relative flex flex-wrap items-center justify-between px-0 mx-auto md:items-start md:text-white">
          <div className="flex  items-center justify-between w-full md:w-auto flex-1 lg:relative">
            <Link to="/">
              <h1 className="w-24 py-4 ml-4 md:ml-0">
                <img src="/assets/logo.png" alt="Balado Airfield" />
              </h1>
            </Link>
            <nav
              className={`${
                isExpanded ? ` block ` : `offscreen`
              }  nav  transition duration-200  md:flex md:items-center w-full md:w-auto  md:bg-transparent md:tracking-widest   font-semibold text-5xl md:uppercase md:text-base fixed top-0 bottom-0 md:static z-20 bg-white md:flex-1`}
            >
              <button
                className="p-4 focus:outline-none md:hidden"
                onClick={() => toggleExpansion(!isExpanded)}
              >
                <AiOutlineCloseCircle />
              </button>

              {[
                {
                  route: `/about`,
                  title: `About`,
                },
                {
                  route: `/fly-with-us`,
                  title: `Fly with us`,
                },
                {
                  route: `/members`,
                  title: `Members`,
                },

                {
                  route: `/shop`,
                  title: `Shop`,
                },
                // {
                //   route: `/adventure-aviation`,
                //   title: `Adventure Aviation`,
                // },
                {
                  route: `/contact`,
                  title: `Contact`,
                },
              ].map((link) => (
                <Link
                  activeClassName="md:border-white"
                  partiallyActive={true}
                  className="block p-4 no-underline md:inline-block md:mt-0 md:ml-6 md:border-b-4 border-transparent md:px-0 md:mx-4"
                  key={link.title}
                  to={link.route}
                >
                  {link.title}
                </Link>
              ))}
            </nav>
            <div className="flex text-white flex-wrap justify-end md:flex-col items-center text-base items-center">
              <ul className="flex text-2xl">
                <li>
                  <a
                    href="https://www.facebook.com/baladoairfield"
                    className="inline-block px-4 "
                  >
                    <FaFacebook />
                  </a>
                </li>
                <li>
                  <a
                    href="https://instagram.com/balado_airfield"
                    className="inline-block px-4"
                  >
                    <FaInstagram />
                  </a>
                </li>
              </ul>
              <button
                className="hidden p-4 md:mb-2 font-semibold no-underline  md:mt-0 md:ml-6 md:tracking-widest focus:outline-none md:uppercase items-center "
                onClick={() => setVisible(true)}
              >
                <AiOutlineShoppingCart size={24} className="mr-4" />
                <span className="hidden md:inline-block">Basket </span>
                {hasItems && <span className="ml-1">({quantity})</span>}
              </button>

              <button
                className="flex items-center border-0 md:hidden focus:outline-none text-white mr-4"
                onClick={() => toggleExpansion(!isExpanded)}
              >
                <svg
                  className="w-8 h-8 fill-current"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>
              <div className="w-full mt-auto " style={{ height: "64px" }}>
                {weather && (
                  <a
                    href="https://www.windy.com/GB-0106?56.212,-4.561,8"
                    target="_blank"
                    rel="noreferrer"
                    className="w-full flex mt-auto justify-end items-center  pr-4 md:flex-row-reverse"
                  >
                    <img
                      style={{ transform: "scale(1.4)" }}
                      className="w-8 md:w-16"
                      src={
                        "https://openweathermap.org/img/wn/" +
                        weather.weather[0]["icon"] +
                        "@2x.png"
                      }
                      alt={weather.weather[0]["{main}"]}
                    />
                    <p className="flex items-center justify-center mx-2 ">
                      <BsCursorFill
                        style={{
                          transform:
                            "rotate(" + (weather.wind.deg - 45) + "deg)",
                        }}
                      />
                      <span className="ml-4">{weather.wind.speed}mph</span>
                    </p>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
